import {
  Box,
  Breadcrumbs,
  Checkbox,
  Chip,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  SvgIcon,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, ReactNode, useMemo, useState } from 'react';
import {
  IoAppsOutline,
  IoCopyOutline,
  IoEyeOffOutline,
  IoEyeOutline,
  IoText,
  IoTrashOutline,
} from 'react-icons/io5';
import { BsBarChartSteps } from 'react-icons/bs';
import { TbArrowsDownUp, TbGauge } from 'react-icons/tb';
import {
  AiOutlineBarChart,
  AiOutlineLineChart,
  AiOutlineNumber,
  AiOutlinePieChart,
} from 'react-icons/ai';
import {
  ChartConfig,
  ChartDrill,
  ChartType,
  SYSTEM_PAYCODES_QUERY,
  selectedMembership,
} from '@intly/core';
import {
  MdOutlineTableChart,
  MdOutlineWaterfallChart,
  MdStackedBarChart,
} from 'react-icons/md';
import ToggleButtons from '../../ui/ToggleButtons/ToggleButtons';
import Label from '../../ui/Label/Label';
import PaycodePicker from '../PaycodePicker/PaycodePicker';
import SelectMenu from '../SelectMenu/SelectMenu';
import {
  EditableLocaleLabel,
  useLocale,
} from '../../ui/EditableLocaleLabel/EditableLocaleLabel';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import { useTranslation } from 'react-i18next';
import { useQuery, useReactiveVar } from '@apollo/client';
import ChartSettingsDrillSelector from './ChartSettingsDrillSelector';
import { ToggleButton } from '../../ui/ToggleButtons/toggleButtons.model';

const ChartItems = {
  LINE: {
    id: 'LINE',
    icon: AiOutlineLineChart,
  },
  BAR: {
    id: 'BAR',
    icon: AiOutlineBarChart,
  },
  PIE: {
    id: 'PIE',
    icon: AiOutlinePieChart,
  },
  NUMBER: {
    id: 'NUMBER',
    icon: AiOutlineNumber,
  },
  STACKED: {
    id: 'STACKED',
    icon: MdStackedBarChart,
  },
  // BUMP_AREA: {
  //   id: 'BUMP_AREA',
  //   icon: MdOutlineWaterfallChart,
  // },
  WATERFALL: {
    id: 'WATERFALL',
    icon: MdOutlineWaterfallChart,
  },
  BAR_DIFF_SPECIAL: {
    id: 'BAR_DIFF_SPECIAL',
    icon: BsBarChartSteps,
  },
  GAUGE: {
    id: 'GAUGE',
    icon: TbGauge,
  },
  TABLE: {
    id: 'TABLE',
    icon: MdOutlineTableChart,
  },
  TEXT: {
    id: 'TEXT',
    icon: IoText,
  },
};

const DEFAULT_DRILL: ChartDrill = {
  reference: {
    type: 'CHART',
    variant: 'local',
  },
  local: [
    {
      order: 0,
      key: 'TOP_LEVEL',
    },
    // {
    //   order: 1,
    //   key: 'company_registration_number',
    // },
    // {
    //   order: 2,
    //   key: 'costCenter',
    // },
    // {
    //   order: 3,
    //   key: 'employment_nummber',
    // },
  ],
};

interface ChartSettingsDrillProps {
  config: ChartConfig;
  onChange: (config: ChartConfig) => void;
}

const ChartSettingsDrill: FC<ChartSettingsDrillProps> = ({
  onChange,
  config,
}) => {
  const { t } = useTranslation('charts');
  const [selectDrill, setSelectDrill] = useState(Boolean(config.drill));

  const handleChangeRadio = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (value === 'PAYCODE') {
      setSelectDrill(false);
      onChange({
        ...config,
        initalDrill: 0,
        drill: null,
      });
    } else {
      setSelectDrill(true);
      onChange({
        ...config,
        initalDrill: 0,
        drill: DEFAULT_DRILL,
      });
    }
  };

  return (
    <>
      <FormControl>
        <FormLabel>{t('DRILL')}</FormLabel>
        <RadioGroup
          row
          value={config.drill ? 'CUSTOM' : 'PAYCODE'}
          onChange={handleChangeRadio}
          sx={{
            flexDirection: 'column',
          }}
        >
          <FormControlLabel
            value="PAYCODE"
            control={<Radio size="small" />}
            label={t('PAYCODE', { ns: 'common' })}
          />
          <FormControlLabel
            value="CUSTOM"
            control={<Radio size="small" />}
            label={t('OTHER', { ns: 'common' })}
          />
        </RadioGroup>
      </FormControl>
      {selectDrill && config?.drill && (
        <Box>
          <ChartSettingsDrillSelector config={config} onChange={onChange} />
        </Box>
      )}
    </>
  );
};

interface ChartSettingsIntialDrillProps {
  config: ChartConfig;
  onChange: (config: ChartConfig) => void;
}

export const ChartSettingsIntialDrill: FC<ChartSettingsIntialDrillProps> = ({
  config,
  onChange,
}) => {
  const { t } = useTranslation('charts');
  const groupId = useReactiveVar(selectedMembership);
  const { data: sysCodes, loading } = useQuery(SYSTEM_PAYCODES_QUERY, {
    variables: { groupId },
  });

  const availableCodes =
    sysCodes?.listSystemPaycodes
      ?.filter((i: any) =>
        config.paycodePath.some((path) => i.id.startsWith(path))
      )
      .map((i: any) => {
        let newString = i.id.replace('*/', '');
        config.paycodePath?.forEach(
          (path) => (newString = newString.replace(path, ''))
        );
        return newString.split('/').length - 1;
      }) ?? [];

  const paycodeDrillDepth = Math.max(...availableCodes);
  const levelArray = paycodeDrillDepth > 0 ? [...Array(paycodeDrillDepth)] : [];

  const customArray = config.drill?.local.map((item) => item.key) ?? [];

  return (
    <>
      <FormLabel>{t('INITIAL_DRILL')}</FormLabel>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {config.drill && (
          <Breadcrumbs maxItems={3}>
            {customArray.map((item, i) => (
              <Chip
                key={i}
                size="small"
                label={t(item)}
                color={config.initalDrill === i ? 'secondary' : 'default'}
                variant={config.initalDrill === i ? 'filled' : 'outlined'}
                onClick={() => {
                  onChange({
                    ...config,
                    initalDrill: i,
                  });
                }}
              />
            ))}
          </Breadcrumbs>
        )}
        {config.paycodePath.length === 0 && !config.drill && (
          <Typography>{t('PAYCODE_IS_REQUIRED')}</Typography>
        )}
        {config.paycodePath.length > 0 && !config.drill && (
          <Breadcrumbs maxItems={3}>
            <Tooltip title={t('SELECTED_PAYCODE_DRILL_TOOLTIP')}>
              <Chip
                size="small"
                label={t('SELECTED_PAYCODE_DRILL')}
                color={config.initalDrill === 0 ? 'secondary' : 'default'}
                variant={config.initalDrill === 0 ? 'filled' : 'outlined'}
                onClick={() => {
                  onChange({
                    ...config,
                    initalDrill: 0,
                  });
                }}
              />
            </Tooltip>

            {!loading &&
              levelArray.map((_, i) => (
                <Tooltip
                  key={i}
                  title={t('DRILL_LEVEL_MIDDLE_TOOLTIP', {
                    level: i + 1,
                  })}
                >
                  <Chip
                    size="small"
                    label={t('DRILL_LEVEL_MIDDLE', {
                      level: i + 1,
                    })}
                    color={
                      config.initalDrill === i + 1 ? 'secondary' : 'default'
                    }
                    variant={
                      config.initalDrill === i + 1 ? 'filled' : 'outlined'
                    }
                    onClick={() => {
                      onChange({
                        ...config,
                        initalDrill: i + 1,
                      });
                    }}
                  />
                </Tooltip>
              ))}
            <Tooltip title={t('SOURCE_PAYCODE_DRILL_TOOLTIP')}>
              <Chip
                size="small"
                label={t('SOURCE_PAYCODE_DRILL')}
                color={
                  config.initalDrill === levelArray.length + 1
                    ? 'secondary'
                    : 'default'
                }
                variant={
                  config.initalDrill === levelArray.length + 1
                    ? 'filled'
                    : 'outlined'
                }
                onClick={() => {
                  onChange({
                    ...config,
                    initalDrill: levelArray.length + 1,
                  });
                }}
              />
            </Tooltip>
          </Breadcrumbs>
        )}
      </Box>
    </>
  );
};

export interface ChartSelectorButtonProps {
  selected: ChartType;
  onChange: (chartType: ChartType) => void;
}

const ChartSelectorButton: FC<ChartSelectorButtonProps> = ({
  selected,
  onChange,
}) => {
  const { t } = useTranslation('charts');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const SelectedIcon = ChartItems[selected].icon;

  const handleSelect = (chart: ChartType) => {
    onChange(chart);
    setAnchorEl(null);
  };

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="secondary" onClick={handleOpen}>
        <SelectedIcon />
      </IconButton>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{ zIndex: 1000 }}
      >
        <ClickAwayListener
          mouseEvent={!anchorEl ? false : 'onClick'}
          onClickAway={handleClose}
        >
          <Paper sx={{ mt: 1 }}>
            <MenuList dense>
              {Object.entries(ChartItems).map(([key, item]) => (
                <MenuItem
                  key={key}
                  onClick={() => handleSelect(key as ChartType)}
                >
                  <ListItemIcon>
                    <item.icon color="inherit" />
                  </ListItemIcon>
                  {t(`${key}_CHART`)}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

interface SectionWrapperProps {
  children: ReactNode;
  title: string;
}

const SectionWrapper: FC<SectionWrapperProps> = ({ children, title }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        border: '1px solid',
        borderColor: 'border',
        borderRadius: 5,
        p: 2,
        my: 2,
        mx: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          bgcolor: 'background.paper',
          position: 'absolute',
          top: '-15px',
          left: '16px',
          px: 0.5,
        }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};

interface MoreMenuButtonProps {
  config: ChartConfig;
  onChange: (config: ChartConfig) => void;
}

const MoreMenuButton: FC<MoreMenuButtonProps> = ({ config, onChange }) => {
  const { t } = useTranslation(['charts', 'common']);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (chart: ChartConfig) => {
    onChange(chart);
  };

  return (
    <>
      <IconButton color="default" onClick={handleOpen}>
        <IoAppsOutline />
      </IconButton>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{ zIndex: 1000 }}
      >
        <ClickAwayListener
          mouseEvent={!anchorEl ? false : 'onClick'}
          onClickAway={handleClose}
        >
          <Paper
            sx={{
              mt: 1,
              p: 1,
              px: 2,
              display: 'flex',
            }}
          >
            <SectionWrapper title="Data">
              <Box sx={{ mb: 1 }}>
                <FormLabel>{t('COLUMN')}</FormLabel>
                <SelectMenu
                  selectedFromOutside={config.property}
                  items={[
                    { value: 'amount', text: t('AMOUNT') },
                    { value: 'quantity', text: t('QUANTITY') },
                    { value: 'unit_price', text: t('UNIT_PRICE') },
                    { value: 'procent', text: t('PERCENT') },
                    // { value: 'info', text: 'Info' },
                  ]}
                  onChange={(item) =>
                    handleChange({
                      ...config,
                      property: item.value as string,
                    })
                  }
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <FormLabel>{t('FORMULA')}</FormLabel>
                <SelectMenu
                  selectedFromOutside={config.key}
                  items={[
                    { value: 'total', text: t('SUM') },
                    { value: 'count', text: t('count') },
                    { value: 'distinct_count', text: t('distinct_count') },
                    { value: 'median', text: 'Median' },
                    { value: 'max', text: 'Max' },
                    { value: 'min', text: 'Min' },
                    { value: 'average', text: t('AVERAGE') },
                    {
                      value: 'averagePerEmployment',
                      text: t('averagePerEmployment'),
                    },
                    {
                      value: 'payslipCount',
                      text: t('payslipCount'),
                    },
                  ]}
                  onChange={(item) =>
                    handleChange({
                      ...config,
                      key: item.value as any,
                    })
                  }
                />
              </Box>
              <Box sx={{ mt: 1 }}>
                <FormLabel>{t('CATEGORY_AXIS')}</FormLabel>
                <SelectMenu
                  selectedFromOutside={config.categoryAxis}
                  items={[
                    {
                      value: null,
                      text: t('PERIODIC'),
                    },
                    {
                      value: 'CATEGORY',
                      text: t('CATEGORY'),
                    },
                  ]}
                  onChange={(item) =>
                    handleChange({
                      ...config,
                      categoryAxis: item.value as string,
                    })
                  }
                />
              </Box>
              {config.key !== 'payslipCount' && (
                <>
                  <Box sx={{ mt: 1 }}>
                    <ChartSettingsDrill
                      onChange={handleChange}
                      config={config}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <ChartSettingsIntialDrill
                      onChange={handleChange}
                      config={config}
                    />
                  </Box>
                </>
              )}
              <Box sx={{ mt: 1 }}>
                <FormLabel>{t('MONTH_COUNT')}</FormLabel>
                <SelectMenu
                  selectedFromOutside={config.monthCount ?? 13}
                  items={[
                    {
                      value: 1,
                      text: `1 ${t('MONTH', {
                        ns: 'common',
                        count: 1,
                      }).toLowerCase()}`,
                    },
                    {
                      value: 3,
                      text: `3 ${t('MONTH', {
                        ns: 'common',
                        count: 3,
                      }).toLowerCase()}`,
                    },
                    {
                      value: 6,
                      text: `6 ${t('MONTH', {
                        ns: 'common',
                        count: 6,
                      }).toLowerCase()}`,
                    },
                    {
                      value: 12,
                      text: `12 ${t('MONTH', {
                        ns: 'common',
                        count: 12,
                      }).toLowerCase()}`,
                    },
                    {
                      value: 13,
                      text: `13 ${t('MONTH', {
                        ns: 'common',
                        count: 13,
                      }).toLowerCase()}`,
                    },
                    {
                      value: 25,
                      text: `25 ${t('MONTH', {
                        ns: 'common',
                        count: 25,
                      }).toLowerCase()}`,
                    },
                    {
                      value: 37,
                      text: `37 ${t('MONTH', {
                        ns: 'common',
                        count: 37,
                      }).toLowerCase()}`,
                    },
                  ]}
                  onChange={(item) =>
                    handleChange({
                      ...config,
                      monthCount: item.value as number,
                    })
                  }
                />
              </Box>

              {/* <Box sx={{ mt: 1 }}>
                <FormLabel>{t('CUMULATIVE_START')}</FormLabel>
                <SelectMenu
                  selectedFromOutside={config.cumulativStart}
                  items={[
                    {
                      value: null,
                      text: 'Inte kumulativ',
                    },
                    {
                      value: 1,
                      text: 'Jan',
                    },
                    {
                      value: 2,
                      text: 'Feb',
                    },
                    {
                      value: 3,
                      text: 'Mar',
                    },
                    {
                      value: 4,
                      text: 'Apr',
                    },
                    {
                      value: 5,
                      text: 'Maj',
                    },
                    {
                      value: 6,
                      text: 'Jun',
                    },
                    {
                      value: 7,
                      text: 'Jul',
                    },
                    {
                      value: 8,
                      text: 'Aug',
                    },
                    {
                      value: 9,
                      text: 'Sep',
                    },
                    {
                      value: 10,
                      text: 'Okt',
                    },
                    {
                      value: 11,
                      text: 'Nov',
                    },
                    {
                      value: 12,
                      text: 'Dec',
                    },
                  ]}
                  onChange={(item) =>
                    handleChange({
                      ...config,
                      cumulativStart: item.value as number,
                    })
                  }
                />
              </Box> */}
            </SectionWrapper>

            <SectionWrapper title={t('RELATIVE_DATA')}>
              <FormControl>
                <FormLabel>{t('TIME_RELATED')}</FormLabel>
                <RadioGroup
                  row
                  value={config.additionaldata || ''}
                  onChange={(_, value) =>
                    handleChange({
                      ...config,
                      additionaldata: value as any,
                    })
                  }
                  sx={{
                    flexDirection: 'column',
                  }}
                >
                  <FormControlLabel
                    value={''}
                    control={<Radio size="small" />}
                    label={t('NONE', { ns: 'common' })}
                  />
                  <FormControlLabel
                    value="PP"
                    control={<Radio size="small" />}
                    label={t('PREVIOUS_PERIOD')}
                  />
                  <FormControlLabel
                    value="YOY"
                    control={<Radio size="small" />}
                    label={t('YOY')}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel>{t('CUMULATIVE')}</FormLabel>
                <RadioGroup
                  row
                  value={config.cumulative ? 'CUMULATIVE' : ''}
                  onChange={(_, value) =>
                    handleChange({
                      ...config,
                      cumulative: value ? true : false,
                    })
                  }
                >
                  <FormControlLabel
                    value={''}
                    control={<Radio size="small" />}
                    label={t('NO', { ns: 'common' })}
                  />
                  <FormControlLabel
                    value="CUMULATIVE"
                    control={<Radio size="small" />}
                    label={t('YES', { ns: 'common' })}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel>{t('SUPPORT_LINES')}</FormLabel>

                <FormControlLabel
                  label="Min"
                  control={
                    <Checkbox
                      size="small"
                      checked={Boolean(config.supportLines.min)}
                      onChange={(event) =>
                        handleChange({
                          ...config,
                          supportLines: {
                            ...config.supportLines,
                            min: event.target.checked ? 'SOURCE' : null,
                          },
                        })
                      }
                    />
                  }
                />
                <FormControlLabel
                  label="Max"
                  control={
                    <Checkbox
                      size="small"
                      checked={Boolean(config.supportLines.max)}
                      onChange={(event) =>
                        handleChange({
                          ...config,
                          supportLines: {
                            ...config.supportLines,
                            max: event.target.checked ? 'SOURCE' : null,
                          },
                        })
                      }
                    />
                  }
                />
                <FormControlLabel
                  label={t('MEDIAN')}
                  control={
                    <Checkbox
                      size="small"
                      checked={Boolean(config.supportLines.mean)}
                      onChange={(event) =>
                        handleChange({
                          ...config,
                          supportLines: {
                            ...config.supportLines,
                            mean: event.target.checked ? 'SOURCE' : null,
                          },
                        })
                      }
                    />
                  }
                />
                <FormControlLabel
                  label={t('AVERAGE')}
                  control={
                    <Checkbox
                      size="small"
                      checked={Boolean(config.supportLines.average)}
                      onChange={(event) =>
                        handleChange({
                          ...config,
                          supportLines: {
                            ...config.supportLines,
                            average: event.target.checked ? 'SOURCE' : null,
                          },
                        })
                      }
                    />
                  }
                />
                <FormControlLabel
                  label={t('TREND')}
                  control={
                    <Checkbox
                      size="small"
                      checked={Boolean(config.supportLines.trend)}
                      onChange={(event) =>
                        handleChange({
                          ...config,
                          supportLines: {
                            ...config.supportLines,
                            trend: event.target.checked ? 'SOURCE' : null,
                          },
                        })
                      }
                    />
                  }
                />
                {Boolean(
                  config.paycodePath.some((p) => p.startsWith('/Budget'))
                ) && (
                  <>
                    <FormControlLabel
                      label={t('BUDGET_DIFF')}
                      control={
                        <Checkbox
                          size="small"
                          checked={Boolean(config.supportLines.budgetDiff)}
                          onChange={(event) =>
                            handleChange({
                              ...config,
                              supportLines: {
                                ...config.supportLines,
                                budgetDiff: event.target.checked
                                  ? 'SOURCE'
                                  : null,
                              },
                            })
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      label={t('BUDGET_DIFF_PERCENTAGE')}
                      control={
                        <Checkbox
                          size="small"
                          checked={Boolean(
                            config.supportLines.budgetDiffPercentage
                          )}
                          onChange={(event) =>
                            handleChange({
                              ...config,
                              supportLines: {
                                ...config.supportLines,
                                budgetDiffPercentage: event.target.checked
                                  ? 'SOURCE'
                                  : null,
                              },
                            })
                          }
                        />
                      }
                    />
                  </>
                )}
              </FormControl>
            </SectionWrapper>

            <SectionWrapper title="Format">
              <FormLabel>{t('DIFFERENCE')}</FormLabel>

              <SelectMenu
                selectedFromOutside={config.difference as string}
                items={[
                  { value: null, text: t('NONE') },
                  { value: 'PREVIOUS', text: t('charts:PREVIOUS_DIFF') },
                  { value: 'MEAN', text: t('charts:MEDIAN_DIFF') },
                  { value: 'AVRAGE', text: t('charts:AVERGE_DIFF') },
                  {
                    value: 'AVRAGE_PER_OCCURRENCE',
                    text: t('charts:AVRAGE_PER_OCCURRENCE'),
                  },
                  {
                    value: 'PREVIOUS_WITH_VALUE',
                    text: t('charts:PREVIOUS_WITH_VALUE'),
                  },
                ]}
                onChange={(i) =>
                  handleChange({ ...config, difference: i.value as any })
                }
              />

              {Boolean(config.additionaldata) && (
                <Box sx={{ mt: 1 }}>
                  <FormLabel>{t('RELATIVE_FORMAT')}</FormLabel>
                  <SelectMenu
                    selectedFromOutside={config.relativeFormat as string}
                    items={[
                      { value: null, text: t('NONE', { ns: 'common' }) },
                      {
                        value: 'PERCENTAGE',
                        text: t('PERCENTAGE_DIFFERENCE'),
                      },
                      {
                        value: 'DIFFERENCE',
                        text: t('DIFFERENCE'),
                      },
                    ]}
                    onChange={(i) =>
                      handleChange({
                        ...config,
                        relativeFormat: i.value as any,
                      })
                    }
                  />
                </Box>
              )}

              <Box sx={{ mt: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(config.sumBySign)}
                      onChange={(_, checked) =>
                        handleChange({
                          ...config,
                          sumBySign: checked,
                        })
                      }
                    />
                  }
                  label={t('SUM_BY_SIGN')}
                />
              </Box>

              <Box sx={{ mt: 1 }}>
                <FormControl>
                  <FormLabel>{t('GROUP_BY')}</FormLabel>

                  <SelectMenu
                    selectedFromOutside={config.groupBy as string}
                    items={[
                      { value: null, text: t('NONE', { ns: 'common' }) },
                      {
                        value: 'company_registration_number',
                        text: t('company_registration_number'),
                      },
                      {
                        value: 'companyNameString',
                        text: t('companyNameString'),
                      },
                      { value: 'project', text: t('project') },
                      { value: 'costCenter', text: t('costCenter') },
                      { value: 'account', text: t('resultUnit') },
                      { value: 'postition', text: t('postition') },
                    ]}
                    onChange={(i) =>
                      handleChange({ ...config, groupBy: i.value as any })
                    }
                  />
                </FormControl>
              </Box>

              <Box sx={{ mt: 1 }}>
                <FormLabel>{t('DIVIDE_BY')}</FormLabel>
                <Box>
                  {config.divideBy?.map((i) => (
                    <Chip
                      variant="outlined"
                      key={i}
                      label={i}
                      onDelete={() => {
                        const temp = config.divideBy?.filter((d) => d !== i);
                        onChange({
                          ...config,
                          divideBy: temp?.length ? temp : null,
                        });
                      }}
                    />
                  ))}
                  {!config.divideBy && (
                    <Typography component="span">
                      {t('NONE', { ns: 'common' })}
                    </Typography>
                  )}
                  <PaycodePicker
                    multiSelect={true}
                    defaultSelected={config.divideBy}
                    onSave={(id) => {
                      onChange({
                        ...config,
                        divideBy: Array.isArray(id) ? id : [],
                      });
                    }}
                  />
                </Box>
              </Box>

              {config.chart === 'TABLE' && config.drill !== null && (
                <Box sx={{ mt: 1 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(config.showNextLevelDetails)}
                        onChange={(_, checked) =>
                          handleChange({
                            ...config,
                            showNextLevelDetails: checked,
                          })
                        }
                      />
                    }
                    label={t('SHOW_NEXT_LEVEL_DETAILS')}
                  />
                </Box>
              )}
            </SectionWrapper>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export interface ChartSettingsProps {
  config: ChartConfig;
  onChange: (config: ChartConfig) => void;
  onDelete?: () => void;
  onCopy?: () => void;
}

const ChartSettings: FC<ChartSettingsProps> = ({
  config,
  onChange,
  onDelete,
  onCopy,
}) => {
  const { t } = useTranslation();
  const [editingLocale, setEditingLocale] = useState(false);
  const translatedTitle = useLocale(config.locales);
  const handleSelectedToggle = (items: string[]) => {
    onChange({
      ...config,
      hidden: !items.includes('hidden'),
      allowDrill: items.includes('allowDrill'),
    });
  };

  const handlePaycodeChange = (paycodePath: string | string[] | undefined) => {
    const initalDrill = 0;
    if (Array.isArray(paycodePath)) {
      onChange({
        ...config,
        initalDrill,
        paycodePath: paycodePath || [],
      });
    } else if (!paycodePath) {
      onChange({
        ...config,
        initalDrill,
        paycodePath: [],
      });
    }
  };

  const firstToggleButtons: ToggleButton[] = useMemo(() => {
    const items = [
      {
        title: (
          <SvgIcon
            inheritViewBox
            component={config.hidden ? IoEyeOutline : IoEyeOffOutline}
          />
        ),
        id: 'hidden',
        show: true,
      },
    ];

    if (!['NUMBER', 'TEXT'].includes(config.chart)) {
      items.push({
        title: (
          <SvgIcon
            inheritViewBox
            component={config.allowDrill ? TbArrowsDownUp : IoCopyOutline}
          />
        ),
        id: 'allowDrill',
        show: true,
      });
    }

    return items;
  }, [config.chart, config.hidden, config.allowDrill]);

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 8,
        border: 'solid 1px',
        borderColor: 'border',
        display: 'flex',
        alignItems: 'center',
        height: 48,
      }}
    >
      <ToggleButtons
        disableScroll
        color="secondary"
        size="small"
        exclusive={false}
        selectedItems={[
          ...(config.hidden ? [] : ['hidden']),
          ...(config.allowDrill ? ['allowDrill'] : []),
        ]}
        onItemsChange={handleSelectedToggle}
        buttons={firstToggleButtons}
      />
      <ChartSelectorButton
        selected={config.chart}
        onChange={(chart) =>
          onChange({
            ...config,
            chart,
          })
        }
      />
      {config.chart === 'NUMBER' && (
        <ColorPicker
          color={config.backgroundColor as string}
          onChange={(color) =>
            onChange({
              ...config,
              backgroundColor: color as any,
            })
          }
        />
      )}
      {editingLocale && (
        <EditableLocaleLabel
          locales={config.locales || []}
          onChange={(locales) => {
            setEditingLocale(false);
            const svText =
              locales.find((l) => l.language === 'sv')?.title || '';
            onChange({
              ...config,
              locales,
              title: svText,
            });
          }}
          onClose={() => {
            setEditingLocale(false);
          }}
        />
      )}
      {!editingLocale && (
        <Label
          value={translatedTitle}
          onClick={() => {
            setEditingLocale(true);
          }}
        />
      )}
      {!['TEXT'].includes(config.chart) && (
        <>
          <PaycodePicker
            multiSelect={true}
            defaultSelected={config.paycodePath}
            onSave={handlePaycodeChange}
          />
          <MoreMenuButton config={config} onChange={onChange} />
        </>
      )}

      {onCopy && (
        <Tooltip title={t('COPY', { ns: 'common' })}>
          <IconButton color="secondary" onClick={onCopy}>
            <IoCopyOutline />
          </IconButton>
        </Tooltip>
      )}
      {onDelete && (
        <Tooltip title={t('REMOVE', { ns: 'common' })}>
          <IconButton color="error" onClick={onDelete}>
            <IoTrashOutline />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default ChartSettings;
